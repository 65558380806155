/* Reset */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import '~@diligentcorp/atlas/dist/atlas/atlas.css';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

/* ol,
ul {
    list-style: none;
} */
blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Remove Edge X */
input::-ms-clear {
    display: none;
}

#filter {
    box-sizing: border-box;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #ffffff;
    border: 1px solid #1e1e1e;
    border-radius: 4px;
    line-height: 1.55;
}

#filter:focus {
    box-shadow: 0px 0px 0px 3px #3e95fa;
}

#AtlasIcon:focus,
#AtlasIcon:active {
    height: 55px;
    width: 56px;
    border: 2px solid #3e95fa;
    border-radius: 2px;
}

.label-small-link:focus,
.label-small-link:active {
    box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px #3e95fa;
    border-radius: 2px;
}

.label-small-link label::first-letter {
    text-transform: uppercase;
}

.label-small-link label {
    text-transform: lowercase;
}

/* Bug in material ui so text input color is not set for date and time pickers */
/*TODO: remove this crap as soon as they fix bug */
.form-input-text-color input {
    color: #3f586a !important;
}

.ck.ck-content p,
.ck.ck-content li {
    font-family: Source sans pro;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #1e1e1e;
}

.ck.ck-editor__editable_inline {
    padding: 0 36px;
}

.ck.ck-toolbar {
    z-index: 100;
}

.ck.ck-reset_all {
    overflow: visible !important;
    z-index: 100;
}

.ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-list,
.ck.ck-dropdown .ck-dropdown__panel .ck-list.ck-rounded-corners {
    background: var(--ck-color-dropdown-panel-background);
    border: 1px solid var(--ck-color-dropdown-panel-border);
    border-radius: var(--ck-border-radius);
    border-top-left-radius: 0;
    box-shadow: var(--ck-drop-shadow), 0 0;
}

.ck.ck-find-and-replace-form {
    background: var(--ck-color-dropdown-panel-background);
    border: 1px solid var(--ck-color-dropdown-panel-border);
    border-radius: var(--ck-border-radius);
    box-shadow: var(--ck-drop-shadow), 0 0;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
    background: var(--ck-color-dropdown-panel-background);
    border: 1px solid var(--ck-color-dropdown-panel-border);
    border-radius: var(--ck-border-radius);
    box-shadow: var(--ck-drop-shadow), 0 0;
    top: 30px;
    left: 0;
}

.ck .ck-insert-table-dropdown__grid {
    box-sizing: border-box;
    width: calc(100% + 120px);
    border: 1px solid var(--ck-color-dropdown-panel-border);
    background: var(--ck-color-dropdown-panel-background);
    border-bottom: none;
    border-radius: var(--ck-border-radius);
}

.ck .ck-insert-table-dropdown__label {
    background: var(--ck-color-dropdown-panel-background);
    width: calc(100% + 120px);
    border-bottom: 1px solid var(--ck-color-dropdown-panel-border);
    border-left: 1px solid var(--ck-color-dropdown-panel-border);
    border-right: 1px solid var(--ck-color-dropdown-panel-border);
    border-radius: var(--ck-border-radius);
}

.ck .ck-toolbar.ck-toolbar_vertical {
    box-sizing: border-box;
    border: 1px solid var(--ck-color-dropdown-panel-border);
    background: var(--ck-color-dropdown-panel-background);
    border-radius: var(--ck-border-radius);
    box-shadow: var(--ck-drop-shadow), 0 0;
}

.ck.ck-button:active,
.ck.ck-button:focus,
a.ck.ck-button:active,
a.ck.ck-button:focus {
    outline: 2px solid #0774ee !important;
    border-radius: 2px !important;
}

.ck.ck-content p,
.ck.ck-content li {
    line-height: 20px;
}

.ck.ck-content ul {
    list-style-type: disc;
}

.ck.ck-content ul ul {
    list-style-type: circle;
}

.ck.ck-content ul ul ul {
    list-style-type: square;
}

.ck.ck-content ul ul ul ul {
    list-style-type: disc;
}

.ck.ck-content ul ul ul ul ul {
    list-style-type: circle;
}

.ck.ck-content ul ul ul ul ul ul {
    list-style-type: square;
}

.ck-sidebar {
    min-height: 0px !important;
}

.ck-sidebar-item {
    padding: 5px 0px;
    position: static !important;
}

.ck.ck-content table td,
.ck.ck-content table th {
    vertical-align: top;
}

.ck.ck-content table th {
    text-align: left;
}

.marker-red {
    background-color: #f50404;
}

.marker-cyan {
    background-color: #00ffff;
}

.marker-magenta {
    background-color: #ff00ff;
}

/* Extension of Draft.js styles */
h1 {
    font-size: xx-large;
}

h2 {
    font-size: x-large;
}

h3 {
    font-size: larger;
}

h4 {
    font-size: large;
}

h5 {
    font-size: medium;
}

h6 {
    font-size: small;
}

.ck.ck-button.ck-heading_heading1>.ck-button__label,
.ck>h1 {
    font-family: Roboto;
    font-weight: normal;
    font-size: 2.083rem;
    line-height: 2.5rem;
    text-transform: uppercase;
}

.ck.ck-button.ck-heading_heading2>.ck-button__label,
.ck>h2 {
    font-family: Roboto;
    font-weight: normal;
    font-size: 1.667rem;
    line-height: 2.083rem;
}

.ck.ck-button.ck-heading_heading3>.ck-button__label,
.ck>h3 {
    font-family: Roboto;
    font-weight: normal;
    font-size: 1.417rem;
    line-height: 1.833rem;
}

.ck.ck-button.ck-heading_heading4>.ck-button__label,
.ck>h4 {
    font-family: Roboto;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.833rem;
}

.ck.ck-button.ck-heading_heading5>.ck-button__label,
.ck>h5 {
    font-family: Roboto;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.417rem;
}

.ck.ck-button.ck-heading_heading6>.ck-button__label,
.ck>h6 {
    font-family: Roboto;
    font-weight: normal;
    font-size: 0.833rem;
    line-height: 1.25rem;
}

/* Remove for full Atlas integration */
*,
*:before,
*:after {
    box-sizing: border-box;
}

.atlas,
.atlas:before,
.atlas:after {
    font-family: 'Source Sans Pro', sans-serif;
}

.atlas,
.atlas:before,
.atlas:after {
    font-family: 'Source Sans Pro', sans-serif;
}

html,
body,
#root {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f5f8f9;
}

/* Draft JS Styling */
.action-style {
    color: #59ac45;
    font-weight: bold;
    min-height: 45px;
    background-repeat: no-repeat;
    padding-left: 30px;
    padding-top: 0px;
    background-image: url('/images/action_icon_2x_NEW.png');
    background-size: 25px 25px;
    background-position-y: 10px;
    word-break: break-all;
}

.action-header {
    display: flex;
    text-transform: capitalize;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px 24px 48px;
    font-family: source sans pro;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-top: 1px solid #e6e6e6;
}

.action-header-button {
    min-width: 23px;
    min-height: 23px;
}

.action-header-button:hover {
    background-color: #94949429;
}

.action-header-button:focus {
    min-width: 23px;
    min-height: 23px;
    box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px #0774ee;
    border-radius: 2px;
}

.accordion-list-icon path {
    stroke: #000000;
}

.action-continue-style {
    color: #41a333;
    font-weight: bolder;
    min-height: 24px;
    line-height: 24px;
    background-repeat: no-repeat;
    padding-left: 20px;
}

/* 
.action-style {
    color:darkblue;
    font-weight: bolder;
    min-height: 200px;
    line-height: 24px;
    background-repeat: no-repeat;
    padding-left:260px;
    background-image: url("/images/unicorn.gif");

}
*/

/* Indentation */

[class^='indented-'] {
    margin-left: 600px;
}

.indented-1 {
    margin-left: 60px;
}

.indented-2 {
    margin-left: 120px;
}

.indented-3 {
    margin-left: 180px;
}

.indented-4 {
    margin-left: 240px;
}

.indented-5 {
    margin-left: 300px;
}

.indented-6 {
    margin-left: 360px;
}

.indented-7 {
    margin-left: 420px;
}

.indented-8 {
    margin-left: 480px;
}

.indented-9 {
    margin-left: 540px;
}

.indented-10 {
    margin-left: 600px;
}

/* Text alignemnt */
.public-DraftEditor-content .text-align-center .public-DraftStyleDefault-block {
    text-align: center;
}

.public-DraftEditor-content .text-align-left .public-DraftStyleDefault-block {
    text-align: left;
}

.public-DraftEditor-content .text-align-right .public-DraftStyleDefault-block {
    text-align: right;
}

.public-DraftEditor-content .text-align-justify .public-DraftStyleDefault-block {
    text-align: justify;
}

/* This is temporary solution before we migrate to 1.2> version of Material UI*/
/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 64px;
    color: #fff;
    text-align: center;
    font-size: 11px;
    opacity: 0.5;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

#cancelBtn:focus,
#addBtn:focus {
    box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px #3e95fa;
    border-radius: 2px;
}

.radioStyle {
    margin-left: 30px;
}

.radioStyle>* {
    color: #000000;
}

.dragHandleStyle {
    cursor: grab;
    width: 27px;
    height: 27px;
    align-items: center;
    display: flex;
    padding: 3px;
    justify-content: center;
}

.dragHandleStyle:focus {
    border: 2px solid #0774ee;
    padding: 6px;
    border-radius: 40px;
    outline: none;
}

.dropDownFocus:focus,
.dropDownFocus:active {
    border: 2px solid #0774ee;
    border-radius: 2px;
}

.FocusStyle:focus,
.FocusStyle:active {
    box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px #0774ee;
    border-radius: 2px;
    box-shadow: 0px 0px 0px 2px #3e95fa;
}

.attendeesSection {
    border: none;
    width: 40px;
    height: 40px;
}

.attendeesSection:focus {
    box-shadow: none;
    box-shadow: 0px 0px 0px 2px #0774ee;
    width: 40px;
    height: 30px;
    outline: none;
    border-radius: 2px;
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper {
    min-height: 370px !important;
}

.collapsed .ag-body-viewport {
    height: calc(100vh - 400px);
    overflow: auto;
}

.expanded .ag-body-viewport {
    height: calc(100vh - 350px);
    overflow: auto;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .collapsed .ag-body-viewport {
        height: calc(100vh - 455px);
        overflow: auto;
    }

    .expanded .ag-body-viewport {
        height: calc(100vh - 400px);
        overflow: auto;
    }
}

.draggableicon {
    cursor: grab;
    width: 27px;
    height: 27px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-right: 2rem;
    margin-left: 15px;
}

.draggableicon:focus {
    border: 2px solid #0774ee;
    padding: 6px;
    border-radius: 40px;
    outline: none;
    margin-right: 1.2rem;
}

.ck-button--AIbutton {
    position: relative;
    height: 32px !important;
    min-width: 213px !important;
    color: #455d82 !important;
    float: right !important;
    margin: auto !important;
    margin-right: 5px !important;
    background-color: white !important;
    padding: 10px !important;
    gap: 10px !important;
    border-radius: 2px !important;
    z-index: 1;
    overflow: hidden; 
    border: none;
}
.ck-button--AIbutton>span {
    font-family: 'Source Sans Pro' !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
}
.ck-button--AIbutton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2px !important;
    padding: 1px;
    background: linear-gradient(
        169.21deg,
        #d3222a 0.16%,
        #b646f6 46.64%,
        #4d1dd5 98.77%
    );
    -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
    mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
}
.ck-button--AIbutton.ck-disabled::before,
.ck-button--AIbutton.ck-disabled {
    background:none;
    background-color: #ffffff !important;
    cursor: not-allowed !important;
    opacity: 1 !important;
    border:1px solid #e6e6e6 !important;
    color: #676767 !important;
    position: relative;
    content: none;

}
.ck-button--AIbutton.ck-disabled>svg>path {
    fill: #676767 !important;
    stop-color: #676767 !important;
}
