/* Toast Container */
.custom-toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Toast Message Box */
.custom-toast {
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 334px;
    padding: 10px;
    overflow: hidden;
    /* animation: slideIn 0.3s ease-in-out; */
}

/* Toast Border Colors */
.custom-toast.failed { border-left: 6px solid #AF292E; }
.custom-toast.unapproved_error { border-left: 6px solid #AF292E; }
.custom-toast.partial_success { border-left: 6px solid #AF292E; }
.custom-toast.automated { border-left: 6px solid #05704B; }

/* Toast Header */
.custom-toast-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

/* Toast Icon */
.custom-toast-icon {
    margin-right: 8px;
    align-self: self-start;
}

/* Toast Message */
.custom-toast-message {
    color: #202935;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 5px;
}

.custom-toast-title.automated {
    color: #05704B;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
}

.custom-toast-title.failed {
    color: #AF292E;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
}

.custom-toast-title.partial_success {
    color: #AF292E;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.custom-toast-title.unapproved_error {
    color: #AF292E;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

/* Toast Buttons */
.custom-toast-buttons {
    display: flex;
    justify-content: flex-end;
}

.custom-toast-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}

.custom-toast-border {
    border: 0.20px solid #f7f7f7;
    width: 106%;
    margin-left: -10px;
}

.custom-toast-buttons .dismiss { color: #455D82; }
.custom-toast-buttons .action { color: #455D82; }

/* Animation */
@keyframes slideIn {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}
